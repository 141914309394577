import { axiosClient } from './base'
const TREASURY_STATISTICS = '/api/statistics/total'

export const treasuryStatistics = ({
  payment,
  payout,
  companyPayout,
  projectsTotal,
  clientId
}) =>
  axiosClient.get(TREASURY_STATISTICS, {
    params: {
      payment,
      payout,
      companyPayout,
      projectsTotal,
      clientId
    }
  })

export const lastActivities = () => axiosClient.get('/api/latest-activities')
