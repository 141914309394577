import { axiosClient } from './base'

const LIST_CONTRACTORS = '/api/contractor'
const EDIT_CONTRACTOR = (id) => `/api/contractor/${id}`
const DELETE_CONTRACTOR = (id) => `/api/contractor/${id}`
export const listContractors = ({ pageNumber, pageSize, q, job, projectId }) =>
  axiosClient.get(LIST_CONTRACTORS, {
    params: {
      pageNumber,
      pageSize,
      q,
      job,
      projectId
    }
  })

export const addNewContractor = ({ name, phoneNumber, phoneNumber2, address, serviceIds }) =>
  axiosClient.post(LIST_CONTRACTORS, {
    name,
    phoneNumber,
    phoneNumber2,
    address,
    serviceIds
  })

export const editContractor = (contractorId, { name, phoneNumber, phoneNumber2, address, serviceIds }) =>
  axiosClient.put(EDIT_CONTRACTOR(contractorId), {
    name,
    phoneNumber,
    phoneNumber2,
    address,
    serviceIds
  })

export const deleteContractor = (contractorId) =>
  axiosClient.delete(DELETE_CONTRACTOR(contractorId))

export const getContractor = (contractorId) =>
  axiosClient.get(DELETE_CONTRACTOR(contractorId))
