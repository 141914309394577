import axios from 'axios'
import { getFirebaseAuth } from '../utils/firebase'
import { setSubscriptionExpiration } from '../features/subscription/slice'

let store

export const injectStore = (_store) => {
  store = _store
}

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, //eslint-disable-line no-undef
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

axiosClient.interceptors.request.use(async function (config) {
  const auth = getFirebaseAuth()
  const token = await auth.currentUser?.getIdToken()
  config.headers.Authorization = `Bearer ${token}`
  return config
})

axiosClient.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    // subscription expiration
    if (error.response.data.code === 'E14006') {
      store.dispatch(setSubscriptionExpiration(true))
    }

    const data = error?.response?.data
    return Promise.reject(data || error)
  }
)

export { axiosClient }
