import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listItems } from '../../api/item'

const initialState = {
  items: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  initialItemsSearchState: {
    fetched: false,
    items: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: ''
  }
}

export const listItemsAsync = createAsyncThunk(
  'item/list',
  async ({ pageSize, pageNumber, q }) => {
    const listResult = await listItems({
      pageSize,
      pageNumber,
      q
    })
    return listResult.data
  }
)

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.unshift(action.payload)
    },
    editItem: (state, action) => {
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })
    },
    deleteItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload)
    },
    useInitialItemsSearchState: (state) => {
      state.items = state.initialItemsSearchState.items
      state.totalCount = state.initialItemsSearchState.totalCount
      state.pageNumber = state.initialItemsSearchState.pageNumber
      state.pageSize = state.initialItemsSearchState.pageSize
      state.q = state.initialItemsSearchState.q
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listItemsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listItemsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.items = action.payload.items
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
        if (
          !state.initialItemsSearchState.fetched ||
          (state.pageNumber !== state.initialItemsSearchState.pageNumber &&
            !state.q)
        ) {
          state.initialItemsSearchState = {
            fetched: true,
            items: action.payload.items,
            totalCount: action.payload.totalCount,
            pageNumber: action.payload.pageNumber,
            pageSize: action.payload.pageSize,
            q: action.payload.q
          }
        }
      })
      .addCase(listItemsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default itemSlice.reducer
export const { addItem, editItem, deleteItem, useInitialItemsSearchState } =
  itemSlice.actions
