export const selectUserProfile = (state) => state.profile.userProfile
export const selectGetProfileStatus = (state) => state.profile.getProfileStatus
export const selectGetUserProfileError = (state) =>
  state.profile.getUserProfileError

export const selectCompanyProfile = (state) => state.profile.companyProfile

export const selectCompanyUsers = (state) => state.profile.listCompanyUsers
export const selectCompanyUsersStatus = (state) =>
  state.profile.getCompanyUsersStatus
export const selectCompanyUsersError = (state) =>
  state.profile.getCompanyUsersError
