import { axiosClient } from './base'

const LIST_SUPPLIERS = '/api/supplier'

const EDIT_SUPPLIERS = (id) => `/api/supplier/${id}`
const DELETE_SUPPLIER = (id) => `/api/supplier/${id}`

export const listSuppliers = ({ pageNumber, pageSize, q, projectId }) =>
  axiosClient.get(LIST_SUPPLIERS, {
    params: {
      pageNumber,
      pageSize,
      q,
      projectId
    }
  })

export const addNewSupplier = ({ name, phoneNumber, phoneNumber2, address }) =>
  axiosClient.post(LIST_SUPPLIERS, {
    name,
    phoneNumber,
    phoneNumber2,
    address
  })

export const deleteSupplier = (supplierId) =>
  axiosClient.delete(DELETE_SUPPLIER(supplierId))

export const editSupplier = (supplierId, supplierDetails) =>
  axiosClient.put(EDIT_SUPPLIERS(supplierId), supplierDetails)

export const getSupplier = (supplierId) =>
  axiosClient.get(DELETE_SUPPLIER(supplierId))
