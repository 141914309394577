import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listProjectPayments, listCompanyPayments } from '../../api/payment'

const initialState = {
  payments: [],
  sumTotal: 0,
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  isAddModalOpen: false,
  isEditModal: false,
  paymentData: '',
  companyPayments: {
    payments: [],
    sumTotal: 0,
    totalCount: 0,
    pageNumber: 1,
    pageSize: 50,
    status: STATE_IDLE,
    error: null
  }
}

export const listProjectPaymentsAsync = createAsyncThunk(
  'project-payment/list',
  async ({ projectId, pageSize, pageNumber }) => {
    const listResult = await listProjectPayments(projectId, {
      pageSize,
      pageNumber
    })
    return listResult.data
  }
)

export const listCompanyPaymentsAsync = createAsyncThunk(
  'company-payment/list',
  async ({ clientId, pageSize, pageNumber } = {}) => {
    const listResult = await listCompanyPayments({
      clientId,
      pageSize,
      pageNumber
    })
    return listResult.data
  }
)

export const projectPaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    showPaymentAddModal: (state) => {
      state.isAddModalOpen = true
      state.isEditModal = false
    },
    showEditModal: (state, action) => {
      state.isAddModalOpen = true
      state.isEditModal = true
      state.paymentData = action.payload
    },
    closeModal: (state) => {
      state.isAddModalOpen = false
      state.isEditModal = !state.isEditModal
    },
    addPayment: (state, action) => {
      state.payments.unshift(action.payload)
      state.sumTotal += action.payload.amount
    },
    updatePayment: (state, action) => {
      const index = state.payments.findIndex(
        (payment) => payment.id === action.payload.id
      )
      state.payments[index] = action.payload
    },
    updateSumTotal: (state, action) => {
      state.sumTotal += action.payload.new - action.payload.old
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjectPaymentsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listProjectPaymentsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.payments = action.payload.payments
        state.sumTotal = action.payload.sumTotal
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listProjectPaymentsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
      .addCase(listCompanyPaymentsAsync.pending, (state) => {
        state.companyPayments.status = STATE_LOADING
      })
      .addCase(listCompanyPaymentsAsync.fulfilled, (state, action) => {
        state.companyPayments.status = STATE_SUCCEEDED
        state.companyPayments.payments = action.payload.payments
        state.companyPayments.sumTotal = action.payload.sumTotal
        state.companyPayments.totalCount = action.payload.totalCount
        state.companyPayments.pageNumber = action.payload.pageNumber
        state.companyPayments.pageSize = action.payload.pageSize
      })
      .addCase(listCompanyPaymentsAsync.rejected, (state, action) => {
        state.companyPayments.status = STATE_FAILED
        console.error(action.error)
        state.companyPayments.error = {
          message: action.error
        }
      })
  }
})

export default projectPaymentSlice.reducer
export const {
  showPaymentAddModal,
  showEditModal,
  closeModal,
  addPayment,
  updatePayment,
  updateSumTotal
} = projectPaymentSlice.actions
