import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { COLORS } from '../constants'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
const TextField = styled(MuiTextField)(({ theme }) => ({
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.primaryBackground,
  '& ::placeholder': {
    fontSize: '14px'
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px 10px'
  },
  '& .MuiInput-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  '& .MuiInputBase-input': {
    color: COLORS.black
  }
}))
export default function SearchTextField ({
  placeholder,
  onSearchInputChange,
  searchValue,
  loading,
  ...props
}) {
  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      onChange={(e) => onSearchInputChange(e.target.value)}
      value={searchValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon style={{ color: COLORS.primary, marginLeft: '8px' }} />
          </InputAdornment>
        ),
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress color="inherit" size={15} />
          </InputAdornment>
        ),
        disableUnderline: true
      }}
      variant="standard"
      {...props}
    />
  )
}
