import { axiosClient } from './base'

const ADD_NEW_JOB = (id) => `/api/project/${id}/job`
const LIST_PROJECT_JOBS = (id) => `/api/project/${id}/job`
const EDIT_JOB = (projectId, jobId) => `/api/project/${projectId}/job/${jobId}`

export const addNewJob = (
  projectId,
  {
    contractorId,
    projectServiceId,
    calculationMethodType,
    calculationMethodPrice,
    quantity,
    total,
    paid,
    due
  }
) =>
  axiosClient.post(ADD_NEW_JOB(projectId), {
    contractorId,
    projectServiceId,
    calculationMethodType,
    calculationMethodPrice,
    quantity,
    total,
    paid,
    due
  })

export const listProjectJobs = (
  projectId,
  { pageNumber, pageSize, q, projectServiceID, all }
) =>
  axiosClient.get(LIST_PROJECT_JOBS(projectId), {
    params: {
      pageNumber,
      pageSize,
      q,
      serviceId: projectServiceID,
      all
    }
  })

export const editJob = (projectId, jobId, {
  contractorId,
  projectServiceId,
  calculationMethodType,
  calculationMethodPrice,
  quantity,
  total,
  paid,
  due
}) => axiosClient.put(EDIT_JOB(projectId, jobId), {
  contractorId,
  projectServiceId,
  calculationMethodType,
  calculationMethodPrice,
  quantity,
  total,
  paid,
  due
})

export const deleteJob = (projectId, jobId) => axiosClient.delete(EDIT_JOB(projectId, jobId))
