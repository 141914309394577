// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import {
  API_KEY,
  AUTH_DOMAIN,
  PROJECT_ID,
  STORAGE_BUCKET,
  SENDER_ID,
  APP_ID,
  MEASUREMENT_ID
} from '../config'

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID
}

// Initialize Firebase
export const initializedFirebaseApp = initializeApp(firebaseConfig)

export const getFirebaseAuth = () => {
  return getAuth()
}

export const sendResetPasswordEmail = (email) => {
  const auth = getFirebaseAuth()
  return sendPasswordResetEmail(auth, email)
}

export const uploadFileToStorage = async (path, file) => {
  const storage = getStorage()
  const storageRef = ref(storage, path)
  const uploadedFile = await uploadBytes(storageRef, file)
  return getDownloadURL(uploadedFile.ref)
}
