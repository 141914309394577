import { axiosClient } from './base'

const LIST_INSTALLMENTS = '/api/installment'
const INSTALLMENT = (id) => `/api/installment/${id}`

export const list = ({ pageNumber, pageSize, unitId }) =>
  axiosClient.get(LIST_INSTALLMENTS, {
    params: {
      pageNumber,
      pageSize,
      unitId
    }
  })

export const addNewInstallment = ({ installmentDate, amount, unitId }) =>
  axiosClient.post(LIST_INSTALLMENTS, { installmentDate, amount, unitId })

export const deleteInstallment = (id) => axiosClient.delete(INSTALLMENT(id))

export const updateInstallment = ({
  id,
  amount,
  installmentDate,
  payDate,
  isPaid
}) =>
  axiosClient.put(INSTALLMENT(id), { amount, installmentDate, payDate, isPaid })
