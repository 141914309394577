import { axiosClient } from './base'

const LIST_SERVICES = '/api/service'
const CREATE_NEW_SERVICE = '/api/service'
const EDIT_SERVICE = (id) => `/api/service/${id}`

export const list = ({ pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_SERVICES, {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const addNewService = ({ name, calculationOptions, contractorIds }) =>
  axiosClient.post(CREATE_NEW_SERVICE, {
    name,
    calculationOptions,
    contractorIds
  })

export const editService = (serviceId, { name, calculationOptions, contractorIds }) =>
  axiosClient.put(EDIT_SERVICE(serviceId), {
    name,
    calculationOptions,
    contractorIds
  })

export const get = (serviceId) => axiosClient.get(EDIT_SERVICE(serviceId))
