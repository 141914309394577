import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listProjectOrders, getOrderItems } from '../../api/order'

const initialState = {
  orders: [],
  sumTotal: 0,
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  selectedOrder: {},
  selectedOrderItems: {},
  getOrderStatus: STATE_IDLE,
  isAddModalOpen: false,
  isEditModal: false,
  orderData: ''
}

export const listProjectOrdersAsync = createAsyncThunk(
  'project-order/list',
  async ({ projectId, pageSize, pageNumber, q, projectServiceID } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 25
    const listResult = await listProjectOrders(projectId, {
      pageSize,
      pageNumber,
      q,
      projectServiceID
    })
    return listResult.data
  }
)

export const getOrderAsync = createAsyncThunk(
  'project-order/get',
  async ({ projectId, orderId, pageSize, pageNumber, q } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 25
    const listResult = await getOrderItems(projectId, orderId, {
      pageSize,
      pageNumber,
      q
    })
    return listResult.data
  }
)

export const projectOrderSlice = createSlice({
  name: 'projectOrder',
  initialState,
  reducers: {
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload
    },
    showOrderAddModal: (state) => {
      state.isAddModalOpen = true
      state.isEditModal = false
    },
    showEditModal: (state, action) => {
      state.isAddModalOpen = true
      state.isEditModal = true
      state.orderData = action.payload
    },
    closeModal: (state) => {
      state.isAddModalOpen = false
      state.isEditModal = !state.isEditModal
    },
    updateIsEditModalAfterEdit: (state) => {
      state.isEditModal = !state.isEditModal
      state.orderData = ''
    },
    addOrder: (state, action) => {
      state.orders.unshift(action.payload)
      state.sumTotal += parseFloat(action.payload.total)
    },
    updateOrder: (state, action) => {
      const order = action.payload
      console.log(order)
      const index = state.orders.findIndex((o) => o.id === order.id)
      if (index !== -1) {
        state.orders[index] = { ...state.orders[index], ...order }
      }
    },
    updateSumTotal: (state, action) => {
      state.sumTotal += action.payload.new - action.payload.old
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjectOrdersAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listProjectOrdersAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.orders = action.payload.orders
        state.sumTotal = action.payload.sumTotal
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listProjectOrdersAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
      .addCase(getOrderAsync.pending, (state) => {
        state.getOrderStatus = STATE_LOADING
      })
      .addCase(getOrderAsync.fulfilled, (state, action) => {
        state.getOrderStatus = STATE_SUCCEEDED
        state.selectedOrderItems.items = action.payload.items
        state.selectedOrderItems.totalCount = action.payload.totalCount
        state.selectedOrderItems.pageNumber = action.payload.pageNumber
        state.selectedOrderItems.pageSize = action.payload.pageSize
        state.selectedOrderItems.q = action.payload.q
      })
      .addCase(getOrderAsync.rejected, (state, action) => {
        state.getOrderStatus = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export const {
  setSelectedOrder,
  showOrderAddModal,
  showEditModal,
  closeModal,
  updateIsEditModalAfterEdit,
  addOrder,
  updateOrder,
  updateSumTotal
} = projectOrderSlice.actions

export default projectOrderSlice.reducer
