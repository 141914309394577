import { axiosClient } from './base'

const GET_PROFILE = '/api/user/me'
const LIST_COMPANY_USERS = '/api/company/me/user'
const CREATE_NEW_COMPANY_USER = '/api/company/me/user'
const UPDATE_COMPANY_USER = (id) => `/api/company/me/user/${id}`
const DELETE_COMPANY_USER = (id) => `/api/company/me/user/${id}`
const INVITE_USER = '/api/user/invite'
const UPDATE_USER_PERMISSION = (userId, permissionId) =>
  `/api/company/me/user/${userId}/permission/${permissionId}`

export const getProfile = () => axiosClient.get(GET_PROFILE)
export const getCompanyUsers = () => axiosClient.get(LIST_COMPANY_USERS)

export const updateCompany = ({
  address,
  governorateId,
  cityId,
  companyName,
  logoUrl
}) =>
  axiosClient.put('/api/company/me/update-profile', {
    address,
    governorateId,
    cityId,
    companyName,
    logoUrl
  })

export const updateProfile = ({ userName, email, phoneNumber, imageUrl }) =>
  axiosClient.put('/api/user/me', {
    userName,
    email,
    phoneNumber,
    imageUrl,
    isActive: true
  })

export const updatePassword = ({ password, passwordConfirmation }) =>
  axiosClient.put('/api/user/me/password', { password, passwordConfirmation })

export const addNewCompanyUser = ({
  userName,
  phoneNumber,
  email,
  password,
  passwordConfirmation
}) =>
  axiosClient.post(CREATE_NEW_COMPANY_USER, {
    userName,
    phoneNumber,
    email,
    password,
    passwordConfirmation
  })

export const editCompanyUser = (companyUserId, companyUserDetails) =>
  axiosClient.put(UPDATE_COMPANY_USER(companyUserId), companyUserDetails)

export const deleteCompanyUser = (companyUserId) =>
  axiosClient.delete(DELETE_COMPANY_USER(companyUserId))

export const inviteUser = ({ email, name }) =>
  axiosClient.post(INVITE_USER, { email, name })

export const getUserInvite = (token) => axiosClient.get(`/api/user/invite/${token}`)

export const setPassword = (token, password) =>
  axiosClient.post(`/api/user/invite/${token}/confirm`, password)

export const assignUserToProject = (userId, projectId) =>
  axiosClient.post('/api/project/user/assign', { userId, projectId })

export const dismissUserfromProject = (userId, projectId) =>
  axiosClient.post('/api/project/user/dismiss', { userId, projectId })
export const updateUserPremission = (userId, permissionId, { canRead, canWrite }) =>
  axiosClient.put(UPDATE_USER_PERMISSION(userId, permissionId), {
    canRead,
    canWrite
  })
