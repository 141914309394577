import { axiosClient } from './base'

const LIST_ITEMS = '/api/item'
const EDIT_ITEM = (id) => `/api/item/${id}`
const DELETE_ITEM = (id) => `/api/item/${id}`

export const listItems = ({ pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_ITEMS, {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const addNewItem = ({ name, price }) =>
  axiosClient.post(LIST_ITEMS, {
    name,
    price
  })

export const editItem = (itemID, { name, price }) =>
  axiosClient.put(EDIT_ITEM(itemID), {
    name,
    price
  })

export const deleteItem = (itemID) =>
  axiosClient.delete(DELETE_ITEM(itemID))
