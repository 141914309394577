import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.css'
import App from './App'
import { store } from './store'
import * as serviceWorker from './serviceWorkerRegistration'
import RTL from './utils/rtl'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { injectStore } from './api/base'

injectStore(store)

// function Copyright () {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Eng-Labs
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   )
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <RTL>
          <App />
          {/* <Box
          bottom={10}
          position={'fixed'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          >
          <Copyright />
        </Box> */}
        </RTL>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

reportWebVitals()
