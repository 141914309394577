import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import MuiDrawer from '@mui/material/Drawer'
import MuiList from '@mui/material/List'

import ListItem from '@mui/material/ListItem'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PeopleIcon from '@mui/icons-material/People'
import { COLORS } from '../constants'
import GroupsIcon from '@mui/icons-material/Groups'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ConstructionIcon from '@mui/icons-material/Construction'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FoundationIcon from '@mui/icons-material/Foundation'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Tooltip, Typography } from '@mui/material'
import { version } from '../../package.json'
import { useSelector } from 'react-redux'
import { selectUserProfile } from '../features/profile/selectors'
const drawerWidth = 200

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = () => ({
  overflowX: 'hidden',
  width: '2px'
})

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px'
    },
    paddingTop: '10px',
    gap: '20px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  background: COLORS.background,
  borderRight: 0,
  // paddingLeft: 1,
  '& .muirtl-yawxep-MuiPaper-root-MuiDrawer-paper': {
    color: 'white'
  },
  ...(open && {
    ...openedMixin(theme),
    [theme.breakpoints.up('lg')]: {
      width: 200
    },
    [theme.breakpoints.down('md')]: {
      width: 200,
      position: 'absolute',
      top: 0,
      right: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: 190,
      position: 'absolute',
      top: 0,
      right: 0
    },
    '& .MuiDrawer-paper ': {
      ...openedMixin(theme),
      [theme.breakpoints.up('lg')]: {
        width: 200
      },
      [theme.breakpoints.down('md')]: {
        width: 200
      },
      [theme.breakpoints.down('sm')]: {
        width: 190
      }
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '&': {
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
      },
      [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    },
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
      }
    }
  })
}))

const List = styled(MuiList)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    [theme.breakpoints.down('md')]: {
      paddingRight: '8px',
      paddingLeft: '8px'
    }
  },
  '& .MuiListItemIcon-root': {
    [theme.breakpoints.down('md')]: {
      minWidth: '40px'
    }
  },
  '& .MuiTypography-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}))

const CustomListItemIcon = ({ children }) => (
  <ListItemIcon style={{ color: 'inherit' }}>{children}</ListItemIcon>
)

const styles = {
  sideBarListItem: {
    color: 'text.third',
    '&:hover': {
      color: 'text.secondary',
      backgroundColor: 'background.primary'
    }
  },
  selectedSideBarListItem: {
    color: 'text.secondary',
    borderLeft: '5px solid',
    borderColor: 'text.secondary',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: COLORS.background,
    borderRight: 0
    // paddingLeft: 1
  }
}
export default function Sidebar({ open, handleDrawerClose }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const userProfile = useSelector(selectUserProfile)

  const [userPermission, setUserPermission] = useState([])

  const resourcesName = [
    'dashboard',
    'project',
    'client',
    'service',
    'item',
    'contractor',
    'supplier',
    'treasury'
  ]

  useEffect(() => {
    if (userProfile) {
      const permissions = resourcesName.map((name) =>
        userProfile.userPermission?.find(
          (permission) => permission.resource.name === name
        )
      )
      setUserPermission(permissions)
    }
  }, [userProfile])

  const onClickMenuItem = useCallback(
    (path) => {
      navigate(path)
      handleDrawerClose()
    },
    [navigate]
  )

  const convertResourceName = (name) => {
    switch (name) {
      case 'dashboard':
        return 'الإحصائيات'
      case 'project':
        return 'المشروعات'
      case 'client':
        return 'العملاء'
      case 'supplier':
        return 'الموردين'
      case 'contractor':
        return 'المقاولين'
      case 'item':
        return 'الخامات'
      case 'service':
        return 'البنود'
      case 'treasury':
        return 'الخزينة'
    }
  }

  const convertResourcePath = (path) => {
    switch (path) {
      case 'dashboard':
        return ''
      case 'project':
        return 'projects'
      case 'client':
        return 'clients'
      case 'supplier':
        return 'suppliers'
      case 'contractor':
        return 'contractors'
      case 'item':
        return 'items'
      case 'service':
        return 'services'
      case 'treasury':
        return 'khazna'
    }
  }

  const convertResourceIcon = (icon) => {
    switch (icon) {
      case 'dashboard':
        return <DashboardIcon />
      case 'project':
        return <FoundationIcon />
      case 'client':
        return <GroupsIcon />
      case 'supplier':
        return <PeopleIcon />
      case 'contractor':
        return <EngineeringIcon />
      case 'item':
        return <ConstructionIcon />
      case 'service':
        return <AssignmentIcon />
      case 'treasury':
        return <PaidOutlinedIcon />
    }
  }

  const path = useMemo(() => location.pathname.slice(1), [location])

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: styles.drawer
      }}
    >
      <div className={classes.toolbar}>
        <Box display={{ xs: 'none', lg: 'block' }}>
          <img src="/civilia-logo.svg" width="100px" alt="Civilia" />
        </Box>
        <Box display={{ xs: 'block', lg: 'none' }}>
          <img src="/civilia-mobile-logo.svg" alt="Civilia" />
        </Box>
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            color: COLORS.primary,
            display: 'flex',
            paddingTop: '10px',
            paddingRight: { xs: '12px', md: '8px' },
            minWidth: '35px',
            height: '35px'
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        overflow={'hidden'}
      >
        <List>
          {userPermission?.map((item) => {
            if (item?.canRead && item?.canWrite) {
              return (
                <Tooltip
                  key={item.resource.id}
                  title={convertResourceName(item?.resource.name)}
                  placement="left"
                  arrow
                >
                  <ListItem
                    button
                    onClick={() =>
                      onClickMenuItem(convertResourcePath(item?.resource.name))
                    }
                    sx={
                      path === convertResourcePath(item?.resource.name)
                        ? styles.selectedSideBarListItem
                        : styles.sideBarListItem
                    }
                  >
                    <CustomListItemIcon>
                      {convertResourceIcon(item?.resource.name)}
                    </CustomListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <span>{convertResourceName(item?.resource.name)}</span>
                    </ListItemText>
                  </ListItem>
                </Tooltip>
              )
            }
            return null
          })}

          <Tooltip title="طلب عرض السعر" placement="left" arrow>
            <ListItem
              button
              onClick={() => onClickMenuItem('offers')}
              sx={
                path === 'offers'
                  ? styles.selectedSideBarListItem
                  : styles.sideBarListItem
              }
            >
              <CustomListItemIcon>
                <LocalOfferOutlinedIcon />
              </CustomListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <span>طلب عرض السعر</span>
              </ListItemText>
            </ListItem>
          </Tooltip>

          <Tooltip title=" تقسيط الوحدات" placement="left" arrow>
            <ListItem
              button
              onClick={() => onClickMenuItem('units')}
              sx={
                path === 'units'
                  ? styles.selectedSideBarListItem
                  : styles.sideBarListItem
              }
            >
              <CustomListItemIcon>
                <ApartmentIcon />
              </CustomListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <span> تقسيط الوحدات </span>
              </ListItemText>
            </ListItem>
          </Tooltip>
          {/* <ListItem
            button
            sx={styles.sideBarListItem}
            onClick={() => onClickMenuItem('/')}
          >
            <CustomListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </CustomListItemIcon>
            <ListItemText>
              <span>أضف مصروفات</span>
            </ListItemText>
          </ListItem> */}

          {/* <ListItem
            button
            sx={styles.sideBarListItem}
            onClick={() => onClickMenuItem('/')}
          >
            <CustomListItemIcon>
              <PaidIcon />
            </CustomListItemIcon>
            <ListItemText>
              <span>التحصيلات</span>
            </ListItemText>
           */}
        </List>
        {open && (
          <Box textAlign="center">
            <Typography
              sx={{
                color: COLORS.third
              }}
            >
              version v{version}
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}
