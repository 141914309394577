import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { list, get } from '../../api/project'

const initialState = {
  projects: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  selectedProject: {},
  selectedProjectUsers: [],
  getProjectStatus: STATE_IDLE,
  clientProjects: [],
  isAddModalOpen: false,
  isEditModal: false,
  projectData: '',
  initialProjectsSearchState: {
    fetched: false,
    projects: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: ''
  }
}

export const listProjectsAsync = createAsyncThunk(
  'project/list',
  async ({ pageSize, pageNumber, q, clientId } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 6
    const listResult = await list({ pageSize, pageNumber, q, clientId })
    return listResult.data
  }
)

export const getProjectAsync = createAsyncThunk(
  'project/get',
  async (projectId) => {
    const getProjectResult = await get(projectId)
    return getProjectResult.data
  }
)

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    showProjectAddModal: (state) => {
      state.isAddModalOpen = true
      state.isEditModal = false
    },
    showEditModal: (state, action) => {
      state.isAddModalOpen = true
      state.isEditModal = true
      state.projectData = action.payload
    },
    closeModal: (state) => {
      state.isAddModalOpen = false
    },
    updateIsEditModalAfterEdit: (state) => {
      state.isEditModal = !state.isEditModal
      state.projectData = ''
    },
    addProject: (state, action) => {
      state.projects.unshift(action.payload)
    },
    updateProject: (state, action) => {
      state.selectedProject = { ...state.selectedProject, ...action.payload }
    },
    deleteProject: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project.id !== action.payload
      )
    },
    addProjectAgreement: (state, action) => {
      state.selectedProject.agreedAmount += +action.payload
      state.selectedProject.due =
        state.selectedProject.agreedAmount - +state.selectedProject.paid
    },
    updateProjectAgreement: (state, action) => {
      state.selectedProject.agreedAmount +=
        +action.payload.new - +action.payload.old
      state.selectedProject.due =
        state.selectedProject.agreedAmount - +state.selectedProject.paid
    },
    deleteProjectAgreement: (state, action) => {
      state.selectedProject.agreedAmount -= +action.payload
      state.selectedProject.due =
        state.selectedProject.agreedAmount - +state.selectedProject.paid
    },
    addProjectPaid: (state, action) => {
      const amount = parseFloat(action.payload)
      state.selectedProject.paid += amount
      state.selectedProject.balance += amount
      state.selectedProject.due -= amount
    },
    updateProjectPaid: (state, action) => {
      const difference =
        parseFloat(action.payload.new) - parseFloat(action.payload.old)
      state.selectedProject.paid += difference
      state.selectedProject.balance += difference
      state.selectedProject.due -= difference
    },
    deleteProjectPaid: (state, action) => {
      const amount = parseFloat(action.payload)
      state.selectedProject.paid -= amount
      state.selectedProject.balance -= amount
      state.selectedProject.due += amount
    },
    addProjectCost: (state, action) => {
      state.selectedProject.cost += parseFloat(action.payload)
      state.selectedProject.balance -= parseFloat(action.payload)
    },
    updateProjectCost: (state, action) => {
      state.selectedProject.cost += +action.payload.new - +action.payload.old
      state.selectedProject.balance =
        +state.selectedProject.paid - +state.selectedProject.cost
    },
    deleteProjectCost: (state, action) => {
      state.selectedProject.cost -= +action.payload
      state.selectedProject.balance =
        +state.selectedProject.paid - +state.selectedProject.cost
    },
    getProjectBalance: (state) => {
      state.selectedProject.balance =
        +state.selectedProject.paid - +state.selectedProject.cost
    },
    getProjectDue: (state) => {
      state.selectedProject.due =
        state.selectedProject.agreedAmount - +state.selectedProject.paid
    },
    useInitialProjectsSearchState: (state) => {
      state.projects = state.initialProjectsSearchState.projects
      state.totalCount = state.initialProjectsSearchState.totalCount
      state.pageNumber = state.initialProjectsSearchState.pageNumber
      state.pageSize = state.initialProjectsSearchState.pageSize
      state.q = state.initialProjectsSearchState.q
    },
    addUserToProject: (state, action) => {
      state.selectedProjectUsers.push(action.payload)
    },
    deleteUserFromProject: (state, action) => {
      state.selectedProjectUsers = state.selectedProjectUsers.filter(
        (user) => user.id !== action.payload
      )
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjectsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listProjectsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.projects = action.payload.projects
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
        if (
          !state.initialProjectsSearchState.fetched ||
          (state.pageNumber !== state.initialProjectsSearchState.pageNumber &&
            !state.q)
        ) {
          state.initialProjectsSearchState = {
            fetched: true,
            projects: action.payload.projects,
            totalCount: action.payload.totalCount,
            pageNumber: action.payload.pageNumber,
            pageSize: action.payload.pageSize,
            q: action.payload.q
          }
        }
      })
      .addCase(listProjectsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        let message
        state.error = {
          message
        }
      })
      .addCase(getProjectAsync.pending, (state) => {
        state.getProjectStatus = STATE_LOADING
      })
      .addCase(getProjectAsync.fulfilled, (state, action) => {
        state.getProjectStatus = STATE_SUCCEEDED
        state.selectedProject = action.payload.project
        state.selectedProjectUsers = action.payload.project.users
      })
      .addCase(getProjectAsync.rejected, (state, action) => {
        state.getProjectStatus = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export const {
  showProjectAddModal,
  showEditModal,
  closeModal,
  updateIsEditModalAfterEdit,
  addProject,
  deleteProject,
  updateProject,
  addProjectAgreement,
  updateProjectAgreement,
  deleteProjectAgreement,
  addProjectPaid,
  updateProjectPaid,
  deleteProjectPaid,
  addProjectCost,
  updateProjectCost,
  deleteProjectCost,
  getProjectBalance,
  getProjectDue,
  useInitialProjectsSearchState,
  addUserToProject,
  deleteUserFromProject
} = projectSlice.actions
export default projectSlice.reducer
