import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listProjectJobs } from '../../api/projectJobs'

const initialState = {
  jobs: [],
  sumTotal: 0,
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  isAddModalOpen: false,
  isEditModal: false,
  jobData: '',
  isDeleteModalOpen: false
}

export const listProjectJobsAsync = createAsyncThunk(
  'project-job/list',
  async ({ projectId, pageSize, pageNumber, q, projectServiceID }) => {
    const listResult = await listProjectJobs(projectId, {
      pageSize,
      pageNumber,
      q,
      projectServiceID
    })
    return listResult.data
  }
)

export const projectJobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    showJopAddModal: (state) => {
      state.isAddModalOpen = true
    },
    closeModal: (state) => {
      state.isAddModalOpen = false
      state.isEditModal = false
      state.jobData = ''
    },
    addPayoutToJobPaid: (state, action) => {
      const job = state.jobs.find(job => job.id === action.payload.jobId)
      if (job) {
        job.paid += parseFloat(action.payload.amount)
        job.due = job.total - job.paid
      }
    },
    updateJobPaid: (state, action) => {
      const job = state.jobs.find(job => job.id === action.payload.jobId)
      if (job) {
        job.paid += action.payload.new - action.payload.old
        job.due = job.total - job.paid
      }
    },
    deletePayoutFromJobPaid: (state, action) => {
      const job = state.jobs.find(job => job.id === action.payload.jobId)
      if (job) {
        job.paid -= parseFloat(action.payload.amount)
        job.due = job.total - job.paid
      }
      state.isEditModal = false
    },
    showEditModal: (state, action) => {
      state.isAddModalOpen = true
      state.isEditModal = true
      state.jobData = action.payload
    },
    showDeleteModal: (state, action) => {
      state.isDeleteModalOpen = true
      state.jobData = action.payload
    },
    hideDeleteModal: (state) => {
      state.isDeleteModalOpen = false
      state.jobData = ''
    },
    addJob: (state, action) => {
      state.jobs.unshift(action.payload)
    },
    updateJob: (state, action) => {
      state.jobs = state.jobs.map((j) => {
        if (j.id === action.payload.id) {
          return action.payload
        } else {
          return j
        }
      })
    },
    updateSumTotal: (state, action) => {
      state.sumTotal += parseFloat(action.payload.new) - parseFloat(action.payload.old)
    },
    deleteJob: (state, action) => {
      state.jobs = state.jobs.filter((j) => j.id !== action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjectJobsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listProjectJobsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.jobs = action.payload.jobs
        state.sumTotal = action.payload.sumTotal
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listProjectJobsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default projectJobSlice.reducer
export const {
  updateJobPaid,
  deletePayoutFromJobPaid,
  showJopAddModal,
  addPayoutToJobPaid,
  closeModal,
  showEditModal,
  showDeleteModal,
  hideDeleteModal,
  updateJob,
  addJob,
  deleteJob,
  updateSumTotal
} = projectJobSlice.actions
