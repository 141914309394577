import Typography from '@mui/material/Typography'
import { COLORS } from '../constants'

const CustomTypographyH1 = ({ children, ...props }) => (
  <Typography
    component="h1"
    variant="h5"
    style={{
      fontSize: '30px',
      color: COLORS.primary
    }}
    {...props}
  >
    {children}
  </Typography>
)

export default CustomTypographyH1
