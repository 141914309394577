import { styled } from '@mui/material/styles'
import MuiMenu from '@mui/material/Menu'
import { COLORS } from '../constants'

const StyledMenu = styled(MuiMenu)(({ theme }) => ({
  right: '-1px',
  top: '1.5px',
  [theme.breakpoints.up('md')]: {
    right: '8px'
  },
  borderTopLeftRadius: '10px',
  paddingTop: '4px',
  paddingBottom: '4px',
  '& .MuiMenuItem-root': {
    padding: 0,
    gap: '8px',
    paddingLeft: '13px',
    fontSize: '10px',
    width: '180px',
    minHeight: '30px',
    '&:hover': {
      backgroundColor: COLORS.menuBackground
    }
  },
  '& .MuiMenu-paper': {
    boxShadow: '0px 1px 2px #00000029'
  }
}))
export default function Menu (props) {
  const { children, ...rest } = props
  return <StyledMenu {...rest}>{children}</StyledMenu>
}
