import { axiosClient } from './base'

const ADD_NEW_PAYOUT = (id) => `/api/project/${id}/payout`
const EDIT_PAYOUT = (projectId, payoutId) =>
  `/api/project/${projectId}/payout/${payoutId}`
const LIST_PROJECT_PAYOUTS = (id) => `/api/project/${id}/payout`
const DELETE_PAYOUT = (projectId, payoutId) =>
  `/api/project/${projectId}/payout/${payoutId}`

const LIST_CONTRACTOR_PAYOUTS = (contractorId) =>
  `/api/payout?contractorId=${contractorId}`

const LIST_SUPPLIER_PAYOUTS = (supplierId) =>
  `/api/payout?supplierId=${supplierId}`

const ADD_PAYOUT = () => '/api/payout'
const PAYOUT = (payoutId) => `/api/payout/${payoutId}`

export const addNewPayout = (
  projectId,
  { payoutDate, name, amount, note, contractorId, jobId, supplierId }
) =>
  axiosClient.post(ADD_NEW_PAYOUT(projectId), {
    payoutDate,
    name,
    amount,
    note,
    contractorId,
    jobId,
    supplierId
  })

export const editPayout = (
  projectId,
  payoutId,
  { payoutDate, name, amount, note, contractorId, jobId, supplierId }
) =>
  axiosClient.put(EDIT_PAYOUT(projectId, payoutId), {
    payoutDate,
    name,
    amount,
    note,
    contractorId,
    jobId,
    supplierId
  })

export const listContractorPayouts = (
  contractorId,
  { pageNumber, pageSize, all, jobId, projectId, fromDate, toDate }
) =>
  axiosClient.get(LIST_CONTRACTOR_PAYOUTS(contractorId), {
    params: {
      pageSize,
      pageNumber,
      all,
      jobId,
      projectId,
      fromDate,
      toDate
    }
  })

export const addContractorPayout = ({
  projectId,
  payoutDate,
  amount,
  note,
  contractorId,
  jobId,
  supplierId
}) =>
  axiosClient.post(ADD_PAYOUT(), {
    payoutDate,
    amount,
    note,
    contractorId,
    jobId,
    supplierId,
    projectId
  })

export const editContractorPayout = ({ payoutId, payoutDate, jobId, amount }) =>
  axiosClient.put(PAYOUT(payoutId), { payoutDate, jobId, amount })

export const deleteContractorPayout = (payoutId) =>
  axiosClient.delete(PAYOUT(payoutId))

export const listProjectPayouts = (
  projectId,
  { pageNumber, pageSize, contractorId, all, q, relationType }
) =>
  axiosClient.get(LIST_PROJECT_PAYOUTS(projectId), {
    params: {
      pageNumber,
      pageSize,
      contractorId,
      all,
      q,
      relationType
    }
  })

export const addPayout = ({
  projectId,
  payoutDate,
  amount,
  note,
  contractorId,
  jobId,
  supplierId
}) =>
  axiosClient.post(ADD_PAYOUT(), {
    payoutDate,
    amount,
    note,
    contractorId,
    jobId,
    supplierId,
    projectId
  })

export const deleteProjectPayout = (projectId, payoutId) =>
  axiosClient.delete(DELETE_PAYOUT(projectId, payoutId))

export const listSupplierPayouts = (
  supplierId,
  { pageNumber, pageSize, all, projectId }
) =>
  axiosClient.get(LIST_SUPPLIER_PAYOUTS(supplierId), {
    params: {
      pageSize,
      pageNumber,
      all,
      projectId
    }
  })

export const editSupplierPayout = ({ payoutId, payoutDate, amount }) =>
  axiosClient.put(PAYOUT(payoutId), { payoutDate, amount })

export const deleteSupplierPayout = (payoutId) =>
  axiosClient.delete(PAYOUT(payoutId))

export const listProjectsPayouts = ({ all, pageSize, pageNumber }) =>
  axiosClient.get('/api/payout', {
    params: {
      all,
      pageSize,
      pageNumber
    }
  })

export const deletePayout = (payoutId) => axiosClient.delete(`/api/payout/${payoutId}`)

export const addNewCompanyPayout = ({ payoutDate, payoutType, amount, note }) =>
  axiosClient.post('/api/company-payout', {
    payoutDate,
    payoutType,
    amount,
    note
  })

export const editCompanyPayout = (
  payoutId,
  { payoutDate, payoutType, amount, note }
) =>
  axiosClient.put(`/api/company-payout/${payoutId}`, {
    payoutDate,
    payoutType,
    amount,
    note
  })

export const listCompanyPayouts = ({ pageSize, pageNumber }) =>
  axiosClient.get('/api/company-payout', {
    params: {
      pageSize,
      pageNumber
    }
  })

export const deleteCompanyPayout = (payoutId) =>
  axiosClient.delete(`/api/company-payout/${payoutId}`)
