import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import MuiAppBar from '@mui/material/AppBar'
import MuiMenu from '@mui/material/Menu'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { COLORS } from '../constants'
import CustomH1 from './CustomH1'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCompanyProfile,
  selectUserProfile
} from '../features/profile/selectors'
import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Menu from './Menu'
import SearchTextField from './SearchTextField'
import {
  listClientsAsync,
  useInitialClientsSearchState
} from '../features/client/slice'
import {
  listProjectsAsync,
  useInitialProjectsSearchState
} from '../features/project/slice'
import {
  listSuppliersAsync,
  useInitialSuppliersSearchState
} from '../features/supplier/slice'
import {
  listContractorsAsync,
  useInitialContractorsSearchState
} from '../features/contractor/slice'
import {
  listItemsAsync,
  useInitialItemsSearchState
} from '../features/item/slice'
import {
  listServicesAsync,
  useInitialServicesSearchState
} from '../features/service/slice'
import SearchIcon from '@mui/icons-material/Search'
import TuneIcon from '@mui/icons-material/Tune'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { setSubscriptionExpiration } from '../features/subscription/slice'

const drawerWidth = 240 // 240

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1
  }
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: 'display 2s',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 200px)'
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 200px)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 190px)'
    },
    '& .MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }),
  ...(!open && {
    transition: 'all 0.5s'
  })
}))

const StyledBox = styled(Box)(() => ({
  height: '40px',
  width: '160px',
  color: '#000',
  position: 'relative',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  backgroundColor: COLORS.primaryBackground,
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  '&:hover': {
    cursor: 'pointer'
  }
}))

const StyledMenu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    boxShadow: '0px 1px 2px #00000029',
    [theme.breakpoints.down('md')]: {
      right: '18px !important'
    },
    [theme.breakpoints.up('md')]: {
      right: 'unset'
    }
  }
}))

export default function AppLayout({ open, handleDrawerOpen }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const companyProfile = useSelector(selectCompanyProfile)
  const userProfile = useSelector(selectUserProfile)

  const imgUrl = userProfile?.imageUrl

  const path = useMemo(() => location.pathname.slice(1), [location])

  const [anchorEl, setAnchorEl] = useState(null)

  const isMenuOpen = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    dispatch(setSubscriptionExpiration(false))
    navigate('logout')
    setAnchorEl(null)
    handleMenuClose()
  }

  const handleProfile = () => {
    navigate('/profile')
    setAnchorEl(null)
    handleMenuClose()
  }

  const onClickShowMore = useCallback(
    (path) => {
      navigate(path)
      handleMenuClose()
    },
    [navigate]
  )

  const menuId = 'profile-menu'

  const [searchAnchorEl, setSearchAnchorEl] = useState(null)
  const openSearchMenu = Boolean(searchAnchorEl)
  const handleSearchClick = (event) => {
    setSearchAnchorEl(event.currentTarget)
  }
  const handleSearchMenuClose = () => {
    setSearchAnchorEl(null)
  }

  const [openMobileSearch, setOpenMobileSearch] = useState(false)

  const [selectedSearchType, setSelectedSearchType] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [searchName, setSearchName] = useState('')

  useEffect(() => {
    setSelectedSearchType(path)
    setSearchValue('')
    if (path) {
      switch (path) {
        case 'projects':
          setSearchName('المشروع')
          break
        case 'clients':
          setSearchName('العميل')
          break
        case 'suppliers':
          setSearchName('المورد')
          break
        case 'contractors':
          setSearchName('المقاول')
          break
        case 'items':
          setSearchName('الخامة')
          break
        case 'services':
          setSearchName('البند')
          break
        default:
          setSearchName('')
      }
    }
  }, [path])

  const translateSelectedSearchType = (selectedSearchType) => {
    if (selectedSearchType === 'projects') {
      return 'المشروعات'
    } else if (selectedSearchType === 'clients') {
      return 'العملاء'
    } else if (selectedSearchType === 'suppliers') {
      return 'الموردين'
    } else if (selectedSearchType === 'contractors') {
      return 'المقاولين'
    } else if (selectedSearchType === 'items') {
      return 'الخامات'
    } else if (selectedSearchType === 'services') {
      return 'البنود'
    }
  }

  const handleSearchType = (e) => {
    const value = e.target.value
    setSearchValue('')

    switch (value) {
      case 'projects':
        setSelectedSearchType('projects')
        navigate('projects')
        setSearchName('المشروع')
        break
      case 'contractors':
        setSelectedSearchType('contractors')
        navigate('contractors')
        setSearchName('المقاول')
        break
      case 'clients':
        setSelectedSearchType('clients')
        navigate('clients')
        setSearchName('العميل')
        break
      case 'items':
        setSelectedSearchType('items')
        navigate('items')
        setSearchName('الخامة')
        break
      case 'services':
        setSelectedSearchType('services')
        navigate('services')
        setSearchName('البند')
        break
      case 'suppliers':
        setSelectedSearchType('suppliers')
        navigate('suppliers')
        setSearchName('المورد')
        break
    }

    handleSearchMenuClose()
  }

  useEffect(() => {
    let timer
    if (searchValue) {
      timer = setTimeout(() => {
        onSearchInputChange(searchValue)
      }, 500)
    } else {
      switch (selectedSearchType) {
        case 'projects':
          dispatch(useInitialProjectsSearchState())
          break
        case 'clients':
          dispatch(useInitialClientsSearchState())
          break
        case 'suppliers':
          dispatch(useInitialSuppliersSearchState())
          break
        case 'contractors':
          dispatch(useInitialContractorsSearchState())
          break
        case 'items':
          dispatch(useInitialItemsSearchState())
          break
        case 'services':
          dispatch(useInitialServicesSearchState())
          break
      }
    }
    return () => clearTimeout(timer)
  }, [searchValue])

  const onSearchInputChange = (value) => {
    if (value.length) {
      switch (selectedSearchType) {
        case 'projects':
          dispatch(
            listProjectsAsync({
              q: value
            })
          )
          break
        case 'clients':
          dispatch(
            listClientsAsync({
              q: value
            })
          )
          break
        case 'suppliers':
          dispatch(
            listSuppliersAsync({
              q: value
            })
          )
          break
        case 'contractors':
          dispatch(
            listContractorsAsync({
              q: value
            })
          )
          break
        case 'items':
          dispatch(
            listItemsAsync({
              q: value
            })
          )
          break
        case 'services':
          dispatch(
            listServicesAsync({
              q: value
            })
          )
          break
      }
    }
  }

  return (
    <>
      <AppBar
        open={open}
        position="fixed"
        sx={{
          backgroundColor: COLORS.background,
          boxShadow: { xs: ' 0px 2px 6px 0px rgba(130, 169, 188, 0.08)', lg: '0 0'}
        }}
      >
        {openMobileSearch && (
          <Toolbar
            sx={{
              paddingLeft: '16px',
              paddingRight: '16px',
              width: '100%'
            }}
          >
            <Box
              onClick={() => {
                setOpenMobileSearch(false)
              }}
              sx={{ width: '6%' }}
            >
              <ArrowForwardIcon style={{ color: COLORS.primary }} />
            </Box>
            <Box sx={{ ml: '8px', display: 'flex', width: '94%' }}>
              <SearchTextField
                placeholder={`بحث باسم ${searchName}`}
                onSearchInputChange={(value) => setSearchValue(value)}
                searchValue={searchValue}
                sx={{
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                  width: '82%',
                  height: '36px'
                }}
              />
              <Box
                onClick={handleSearchClick}
                sx={{
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                  width: '17.5%',
                  height: '36px',
                  backgroundColor: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <TuneIcon
                  style={{ color: COLORS.white, width: '24px', height: '24px' }}
                />
              </Box>
            </Box>
          </Toolbar>
        )}
        {!openMobileSearch && (
          <Toolbar
            sx={{
              paddingLeft: { xs: '16px', lg: '21px' },
              paddingRight: { xs: '8px', lg: '38px' }
            }}
          >
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 2,
                paddingLeft: { xs: 1, md: 2 },
                paddingRight: 2,
                ...(open && { display: 'none' })
              }}
              style={{
                color: COLORS.primary,
                width: '50px',
                height: '50px'
              }}
            >
              <MenuIcon />
            </IconButton>

            <CustomH1
              fontSize={{ xs: '20px !important', sm: '25px !important' }}
              marginLeft={{ xs: '1', lg: 3 }}
            >
              {companyProfile.name}
            </CustomH1>

            <div className={classes.grow} />
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex'
                },
                gap: '1px'
              }}
            >
              <SearchTextField
                placeholder={`بحث باسم ${searchName}`}
                onSearchInputChange={(value) => setSearchValue(value)}
                searchValue={searchValue}
                sx={{
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                  width: '290px'
                }}
              />
              <StyledBox onClick={handleSearchClick}>
                <Typography sx={{ fontSize: '14px' }}>
                  {translateSelectedSearchType(selectedSearchType)}
                </Typography>
                <ArrowDropDownIcon
                  sx={{
                    position: 'absolute',
                    left: '72%',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'gray'
                  }}
                />
              </StyledBox>
            </Box>

            <div className={classes.grow} />
            <Typography
              sx={{ display: { xs: 'none', md: 'block' } }}
              component={'h3'}
              color="text.secondary"
            >
              {userProfile.name}
            </Typography>
            <Box
              onClick={() => {
                setOpenMobileSearch(true)
              }}
              sx={{
                width: '28px',
                height: '28px',
                borderRadius: '100%',
                backgroundColor: 'text.gray',
                mr: '8px',
                display: {
                  xs: 'flex',
                  md: 'none'
                },
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SearchIcon style={{ color: COLORS.primary }} />
            </Box>
            <IconButton
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              style={{
                color: COLORS.primary
              }}
            >
              {imgUrl ? (
                <Box
                  sx={{ width: '36px', height: '36px', borderRadius: '100%' }}
                >
                  <img
                    src={imgUrl}
                    alt="img"
                    width={'100%'}
                    height={'100%'}
                    style={{ borderRadius: '100%', objectFit: 'contain' }}
                  />
                </Box>
              ) : (
                <AccountCircle sx={{ fontSize: '28px' }} />
              )}
            </IconButton>
          </Toolbar>
        )}
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        elevation={0}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          style={{ fontSize: '14px', color: COLORS.black }}
          onClick={handleProfile}
        >
          حسابي
        </MenuItem>

        {userProfile.role !== 'user' && (
          <MenuItem
            style={{ fontSize: '14px', color: COLORS.black }}
            onClick={() => onClickShowMore('subscription-details')}
          >
            تفاصيل الاشتراك
          </MenuItem>
        )}

        <MenuItem
          style={{ fontSize: '14px', color: COLORS.black }}
          onClick={handleLogout}
        >
          تسجيل الخروج
        </MenuItem>
      </Menu>

      <StyledMenu
        anchorEl={searchAnchorEl}
        elevation={0}
        id="edit-delete"
        keepMounted
        open={openSearchMenu}
        onClose={handleSearchMenuClose}
      >
        <RadioGroup
          sx={{
            '&.MuiFormGroup-root': {
              width: '162px'
            }
          }}
          value={selectedSearchType}
          onChange={(e) => handleSearchType(e)}
        >
          <FormControlLabel
            value={'projects'}
            control={
              <Radio
                sx={{
                  '&': {
                    color: 'text.third'
                  },
                  '&.Mui-checked': {
                    color: 'text.secondary'
                  }
                }}
              />
            }
            label="المشروعات"
            sx={{
              height: { xs: '35px', lg: '42px' },
              paddingLeft: '10px',
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '14px' },
                color: 'text.black'
              }
            }}
          />
          <FormControlLabel
            value={'clients'}
            control={
              <Radio
                sx={{
                  '&': {
                    color: 'text.third'
                  },
                  '&.Mui-checked': {
                    color: 'text.secondary'
                  }
                }}
              />
            }
            label="العملاء"
            sx={{
              height: { xs: '35px', lg: '42px' },
              paddingLeft: '10px',
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '14px' },
                color: 'text.black'
              }
            }}
          />
          <FormControlLabel
            value={'suppliers'}
            control={
              <Radio
                sx={{
                  '&': {
                    color: 'text.third'
                  },
                  '&.Mui-checked': {
                    color: 'text.secondary'
                  }
                }}
              />
            }
            label="الموردين"
            sx={{
              height: { xs: '35px', lg: '42px' },
              paddingLeft: '10px',
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '14px' },
                color: 'text.black'
              }
            }}
          />
          <FormControlLabel
            value={'contractors'}
            control={
              <Radio
                sx={{
                  '&': {
                    color: 'text.third'
                  },
                  '&.Mui-checked': {
                    color: 'text.secondary'
                  }
                }}
              />
            }
            label=" المقاولين"
            sx={{
              height: { xs: '35px', lg: '42px' },
              paddingLeft: '10px',
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '14px' },
                color: 'text.black'
              }
            }}
          />
          <FormControlLabel
            value={'items'}
            control={
              <Radio
                sx={{
                  '&': {
                    color: 'text.third'
                  },
                  '&.Mui-checked': {
                    color: 'text.secondary'
                  }
                }}
              />
            }
            label=" الخامات"
            sx={{
              height: { xs: '35px', lg: '42px' },
              paddingLeft: '10px',
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '14px' },
                color: 'text.black'
              }
            }}
          />
          <FormControlLabel
            value={'services'}
            control={
              <Radio
                sx={{
                  '&': {
                    color: 'text.third'
                  },
                  '&.Mui-checked': {
                    color: 'text.secondary'
                  }
                }}
              />
            }
            label="البنود"
            sx={{
              height: { xs: '35px', lg: '42px' },
              paddingLeft: '10px',
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '14px' },
                color: 'text.black'
              }
            }}
          />
        </RadioGroup>
      </StyledMenu>
    </>
  )
}
