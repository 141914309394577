import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listProjectServices } from '../../api/projectService'
import { get } from '../../api/service'

const initialState = {
  services: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  selectedProjectService: {},
  getProjectServiceStatus: STATE_IDLE,
  selectedService: {},
  getServiceStatus: STATE_IDLE,
  isServiceModalOpen: false,
  isEditModal: false,
  projectServiceData: ''
}

export const getServiceAsync = createAsyncThunk(
  'service/get',
  async (serviceId) => {
    const getServiceResult = await get(serviceId)
    return getServiceResult.data
  }
)

export const listProjectServicesAsync = createAsyncThunk(
  'project-service/list',
  async ({ projectId, pageSize, pageNumber, q } = {}) => {
    pageNumber = pageNumber || 1
    const listResult = await listProjectServices(projectId, {
      pageSize,
      pageNumber,
      q
    })
    return listResult.data
  }
)

export const projectServiceSlice = createSlice({
  name: 'projectService',
  initialState,
  reducers: {
    updateProjectServiceTotalAfterAdd: (state, action) => {
      const projectService = state.services.find(
        (service) => service.id === action.payload.projectServiceId
      )
      projectService.total += parseFloat(action.payload.amount)
    },
    updateProjectServiceTotalAfterEdit: (state, action) => {
      const projectService = state.services.find(
        (service) => service.id === action.payload.projectServiceId
      )
      projectService.total +=
        parseFloat(action.payload.new) - action.payload.old
    },
    updateProjectServiceTotalAfterDelete: (state, action) => {
      const projectService = state.services.find(
        (service) => service.id === action.payload.projectServiceId
      )
      projectService.total -= parseFloat(action.payload.amount)
    },
    showServiceAddModal: (state) => {
      state.isServiceModalOpen = true
    },
    showEditModal: (state, action) => {
      state.isServiceModalOpen = true
      state.isEditModal = true
      console.log(action.payload)
      state.projectServiceData = action.payload
    },
    closeModal: (state) => {
      state.isServiceModalOpen = false
    },
    updateIsEditModalAfterEdit: (state) => {
      state.isEditModal = false
      state.projectServiceData = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjectServicesAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listProjectServicesAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.services = action.payload.services
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listProjectServicesAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
      .addCase(getServiceAsync.pending, (state) => {
        state.getServiceStatus = STATE_LOADING
      })
      .addCase(getServiceAsync.fulfilled, (state, action) => {
        state.getServiceStatus = STATE_SUCCEEDED
        state.selectedService = action.payload.service
      })
      .addCase(getServiceAsync.rejected, (state, action) => {
        state.getServiceStatus = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default projectServiceSlice.reducer
export const {
  showServiceAddModal,
  closeModal,
  showEditModal,
  updateIsEditModalAfterEdit,
  updateProjectServiceTotalAfterAdd,
  updateProjectServiceTotalAfterEdit,
  updateProjectServiceTotalAfterDelete
} = projectServiceSlice.actions
