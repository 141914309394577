import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  subscriptionExpiration: false
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionExpiration: (state, action) => {
      state.subscriptionExpiration = action.payload
    }
  }
})

export const { setSubscriptionExpiration } = subscriptionSlice.actions

export default subscriptionSlice.reducer
