import { axiosClient } from './base'

const ADD_NEW_PAYMENT = (id) => `/api/project/${id}/payment`
const LIST_PROJECT_PAYMENTS = (id) => `/api/project/${id}/payment`
const DELETE_PAYMENT = (projectId, paymentId) =>
  `/api/project/${projectId}/payment/${paymentId}`
const EDIT_PAYMENT = (projectId, paymentId) =>
  `/api/project/${projectId}/payment/${paymentId}`

const LIST_COMPANY_PAYMENTS = '/api/payment'
const LIST_COMPANY_PAYMENTS_PAYOUTS = '/api/payment-payout'

export const addNewPayment = (projectId, { paymentDate, amount, note }) =>
  axiosClient.post(ADD_NEW_PAYMENT(projectId), {
    paymentDate,
    amount,
    note
  })

export const editPayment = (
  projectId,
  paymentId,
  { paymentDate, amount, note }
) =>
  axiosClient.put(EDIT_PAYMENT(projectId, paymentId), {
    paymentDate,
    amount,
    note
  })

export const deletePayment = (projectId, paymentId) =>
  axiosClient.delete(DELETE_PAYMENT(projectId, paymentId))

export const listProjectPayments = (projectId, { pageNumber, pageSize, all }) =>
  axiosClient.get(LIST_PROJECT_PAYMENTS(projectId), {
    params: {
      pageNumber,
      pageSize,
      all
    }
  })

export const listCompanyPayments = ({ clientId, pageNumber, pageSize }) =>
  axiosClient.get(LIST_COMPANY_PAYMENTS, {
    params: {
      clientId,
      pageNumber,
      pageSize
    }
  })

export const getCompanyPaymentsAndPayouts = ({ pageNumber, pageSize }) =>
  axiosClient.get(LIST_COMPANY_PAYMENTS_PAYOUTS, {
    params: {
      pageNumber,
      pageSize
    }
  })
