import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/slice'
import clientReducer from './features/client/slice'
import projectReducer from './features/project/slice'
import projectServiceReducer from './features/projectService/slice'
import paymentReducer from './features/payment/slice'
import jobReducer from './features/job/slice'
import orderReducer from './features/order/slice'
import payoutReducer from './features/payout/slice'
import serviceReducer from './features/service/slice'
import supplierReducer from './features/supplier/slice'
import contractorReducer from './features/contractor/slice'
import itemReducer from './features/item/slice'
import profileReducer from './features/profile/slice'
import khaznaReducer from './features/khazna/slice'
import offerReducer from './features/offers/slice'
import installmentReducer from './features/installment/slice'
import subscriptionReducer from './features/subscription/slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    client: clientReducer,
    project: projectReducer,
    projectService: projectServiceReducer,
    payment: paymentReducer,
    job: jobReducer,
    order: orderReducer,
    payout: payoutReducer,
    service: serviceReducer,
    supplier: supplierReducer,
    contractor: contractorReducer,
    item: itemReducer,
    profile: profileReducer,
    khazna: khaznaReducer,
    offer: offerReducer,
    installment: installmentReducer,
    subscription: subscriptionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})
