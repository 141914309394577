import { axiosClient } from './base'

const ADD_NEW_SERVICE = (id) => `/api/project/${id}/service`
const LIST_PROJECT_SERVICES = (id) => `/api/project/${id}/service`
const GET_PROJECT_SERVICE = (projectId, serviceId) =>
  `/api/project/${projectId}/service/${serviceId}`

export const addNewService = (
  projectId,
  { serviceId, calculationMethodType, calculationMethodPrice, quantity, total }
) =>
  axiosClient.post(ADD_NEW_SERVICE(projectId), {
    serviceId,
    calculationMethodType,
    calculationMethodPrice,
    quantity,
    total
  })

export const editProjectService = (
  projectId, projectServiceId,
  { serviceId, calculationMethodType, calculationMethodPrice, quantity, total }
) =>
  axiosClient.put(GET_PROJECT_SERVICE(projectId, projectServiceId), {
    serviceId,
    calculationMethodType,
    calculationMethodPrice,
    quantity,
    total
  })

export const listProjectServices = (projectId, { pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_PROJECT_SERVICES(projectId), {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const getProjectService = (projectId, projectServiceId) =>
  axiosClient.get(GET_PROJECT_SERVICE(projectId, projectServiceId))

export const updateProjectService = (
  projectId,
  projectServiceId,
  { calculationMethodType, status }
) =>
  axiosClient.put(GET_PROJECT_SERVICE(projectId, projectServiceId), {
    calculationMethodType,
    status
  })

export const deleteProjectService = (projectId, projectServiceId) =>
  axiosClient.delete(GET_PROJECT_SERVICE(projectId, projectServiceId))
