import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { treasuryStatistics } from './../../api/statistics'

const initialState = {
  paymentsSum: 0,
  payoutsSum: 0,
  companyPayoutsSum: 0,
  status: STATE_IDLE,
  error: null
}

export const treasuryStatisticsDataAsync = createAsyncThunk(
  'treasury-statistics',
  async ({ payment, payout, companyPayout }) => {
    const statisticsResult = await treasuryStatistics({
      payment,
      payout,
      companyPayout
    })
    return statisticsResult.data
  }
)

export const treasuryStatisticsSlice = createSlice({
  name: 'khazna',
  initialState,
  reducers: {
    updateCompanyPayoutsSumAfterAddPayout: (state, action) => {
      const amount = parseFloat(action.payload)
      state.companyPayoutsSum += amount
    },
    updateCompanyPayoutsSumAfterEditPayout: (state, action) => {
      const difference =
        parseFloat(action.payload.new) - parseFloat(action.payload.old)
      state.companyPayoutsSum += difference
    },
    updateCompanyPayoutsSumAfterDeletePayout: (state, action) => {
      const amount = parseFloat(action.payload)
      state.companyPayoutsSum -= amount
    },
    updateProjectsPayoutsSumAfterAddPayout: (state, action) => {
      const amount = parseFloat(action.payload)
      state.payoutsSum += amount
    },
    updateProjectsPayoutsSumAfterEditPayout: (state, action) => {
      const difference =
        parseFloat(action.payload.new) - parseFloat(action.payload.old)
      state.payoutsSum += difference
    },
    updateProjectsPayoutsSumAfterDeletePayout: (state, action) => {
      const amount = parseFloat(action.payload)
      state.payoutsSum -= amount
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(treasuryStatisticsDataAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(treasuryStatisticsDataAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.paymentsSum = action.payload.paymentsSum
        state.payoutsSum = action.payload.payoutsSum
        state.companyPayoutsSum = action.payload.companyPayoutsSum
      })
      .addCase(treasuryStatisticsDataAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default treasuryStatisticsSlice.reducer
export const {
  updateCompanyPayoutsSumAfterAddPayout,
  updateCompanyPayoutsSumAfterEditPayout,
  updateCompanyPayoutsSumAfterDeletePayout,
  updateProjectsPayoutsSumAfterAddPayout,
  updateProjectsPayoutsSumAfterEditPayout,
  updateProjectsPayoutsSumAfterDeletePayout
} = treasuryStatisticsSlice.actions
