import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { STATE_LOADING } from '../constants'

import { selectCurrentUser, selectStatus } from '../features/auth/selectors'
import LoadingSpinner from './LoadingSpinner'
import AppLayout from './AppLayout'

export default function RequireAuth() {
  const currentUser = useSelector(selectCurrentUser)
  const getAuthStatus = useSelector(selectStatus)

  const location = useLocation()

  if (getAuthStatus === STATE_LOADING) {
    return <LoadingSpinner />
  }
  if (!currentUser) {
    return <Navigate to={'/login'} state={{ from: location }} />
  }
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  )
}
