import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATE_FAILED, STATE_IDLE, STATE_LOADING, STATE_SUCCEEDED } from '../../constants'
import { getOfferItems } from '../../api/offer'

const initialState = {
  offerItems: {
    status: STATE_IDLE,
    items: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: '',
    error: null
  }
}

export const listOfferItemsAsync = createAsyncThunk(
  'offer-item/list',
  async (offerId, { pageSize, pageNumber } = {}) => {
    const listResult = await getOfferItems(offerId, { pageSize, pageNumber })
    return listResult.data
  })

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    addItemToOffer: (state, action) => {
      state.offerItems.items.unshift(action.payload)
    },
    deleteItem: (state, action) => {
      state.offerItems.items = state.offerItems.items.filter(
        (item) => item.id !== action.payload.id
      )
    },
    updateItem: (state, action) => {
      state.offerItems.items = state.offerItems.items.map(item => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOfferItemsAsync.pending, (state) => {
        state.offerItems.status = STATE_LOADING
      })
      .addCase(listOfferItemsAsync.fulfilled, (state, action) => {
        state.offerItems.status = STATE_SUCCEEDED
        state.offerItems.items = action.payload.items
        state.offerItems.totalCount = action.payload.totalCount
        state.offerItems.pageNumber = action.payload.pageNumber
        state.offerItems.pageSize = action.payload.pageSize
      })
      .addCase(listOfferItemsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        let message
        state.offerItems.error = {
          message
        }
      })
  }
})
export default offerSlice.reducer
export const { addItemToOffer, deleteItem, updateItem } = offerSlice.actions
