import { axiosClient } from './base'

const LIST_PROJECTS = '/api/project'
const CREATE_NEW_PROJECT = '/api/project'
const EDIT_PROJECT = (id) => `/api/project/${id}`

export const list = ({ pageNumber, pageSize, q, all, clientId, contractorId }) =>
  axiosClient.get(LIST_PROJECTS, {
    params: {
      pageNumber,
      pageSize,
      q,
      all,
      clientId,
      contractorId
    }
  })

export const createNewProject = ({ clientId, name, address, type, supervisionRatio }) =>
  axiosClient.post(CREATE_NEW_PROJECT, {
    clientId,
    name,
    address,
    type,
    supervisionRatio
  })

export const addProjectImages = (projectId, photos) =>
  axiosClient.post(`/api/project/${projectId}/attach-photo`, { photos })

export const editProject = (projectId, { clientId, name, address, agreedAmount, supervisionRatio }) =>
  axiosClient.put(EDIT_PROJECT(projectId), {
    clientId,
    name,
    address,
    agreedAmount,
    supervisionRatio
  })
export const deleteProject = (projectId) =>
  axiosClient.delete(EDIT_PROJECT(projectId))

export const get = (projectId) => axiosClient.get(EDIT_PROJECT(projectId))
