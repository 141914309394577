import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import {
  listCompanyPayouts,
  listProjectPayouts,
  listProjectsPayouts
} from '../../api/payout'

const initialState = {
  payouts: [],
  sumTotal: 0,
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null,
  isAddModalOpen: false,
  isEditModal: false,
  payoutData: '',
  companyPayouts: {
    payouts: [],
    status: STATE_IDLE,
    error: null,
    pageNumber: 1,
    pageSize: 50,
    totalCount: 0
  },
  projectsPayouts: {
    payouts: [],
    status: STATE_IDLE,
    error: null,
    pageNumber: 1,
    pageSize: 50,
    totalCount: 0
  }
}

export const listProjectPayoutsAsync = createAsyncThunk(
  'project-payout/list',
  async ({ projectId, pageNumber, pageSize, contractorId, q, relationType } = {}) => {
    pageNumber = pageNumber || 1
    const listResult = await listProjectPayouts(projectId, {
      pageNumber,
      pageSize,
      contractorId,
      q,
      relationType
    })
    return listResult.data
  }
)

export const listCompanyPayoutsAsync = createAsyncThunk(
  'company-payout/list',
  async ({
    pageNumber,
    pageSize
  } = {}) => {
    pageNumber = pageNumber || 1
    const listResult = await listCompanyPayouts({
      pageNumber,
      pageSize
    })
    return listResult.data
  }
)

export const listProjectsPayoutsAsync = createAsyncThunk(
  'projects-payout/list',
  async ({ pageNumber, pageSize } = {}) => {
    pageNumber = pageNumber || 1
    const listResult = await listProjectsPayouts({
      pageNumber,
      pageSize
    })
    return listResult.data
  }
)

export const projectPayoutSlice = createSlice({
  name: 'payout',
  initialState,
  reducers: {
    showPayoutAddModal: (state) => {
      state.isAddModalOpen = true
      state.isEditModal = false
    },
    showEditModal: (state, action) => {
      state.isAddModalOpen = true
      state.isEditModal = true
      state.payoutData = action.payload
    },
    closeModal: (state) => {
      state.isAddModalOpen = false
      state.isEditModal = !state.isEditModal
    },
    addPaidFromOrderToPayout: (state, action) => {
      state.payouts.unshift(action.payload)
      state.sumTotal += action.payload.amount
    },
    addPaidFromJobtoPayout: (state, action) => {
      state.payouts.unshift(action.payload)
      state.sumTotal += action.payload.amount
    },
    addPayout: (state, action) => {
      state.payouts.unshift(action.payload)
      state.sumTotal += action.payload.amount
    },
    updatePayout: (state, action) => {
      const index = state.payouts.findIndex(
        (payout) => payout.id === action.payload.id
      )
      state.payouts[index] = action.payload
    },
    updateSumTotal: (state, action) => {
      state.sumTotal += action.payload.new - action.payload.old
    },
    addCompanyPayout: (state, action) => {
      state.companyPayouts.payouts.unshift(action.payload)
    },
    updateCompanyPayout: (state, action) => {
      const index = state.companyPayouts.payouts.findIndex(
        (payout) => payout.id === action.payload.id
      )
      state.companyPayouts.payouts[index] = action.payload
    },
    deleteCompanyPayoutSlice: (state, action) => {
      state.companyPayouts.payouts = state.companyPayouts.payouts.filter(
        (payout) => payout.id !== action.payload.id
      )
    },
    addProjectsPayout: (state, action) => {
      state.projectsPayouts.payouts.unshift(action.payload)
    },
    updateProjectsPayout: (state, action) => {
      state.projectsPayouts.payouts = state.projectsPayouts.payouts.map(
        (payout) => {
          if (payout.id === action.payload.id) {
            return {
              ...payout,
              amount: action.payload.amount,
              payoutDate: action.payload.payoutDate,
              note: action.payload.note
            }
          } else {
            return payout
          }
        }
      )
    },
    deleteProjectsPayoutSlice: (state, action) => {
      state.projectsPayouts.payouts = state.projectsPayouts.payouts.filter(
        (payout) => payout.id !== action.payload.id
      )
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProjectPayoutsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listProjectPayoutsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.payouts = action.payload.payouts
        state.sumTotal = action.payload.sumTotal
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listProjectPayoutsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
      .addCase(listCompanyPayoutsAsync.pending, (state) => {
        state.companyPayouts.status = STATE_LOADING
      })
      .addCase(listCompanyPayoutsAsync.fulfilled, (state, action) => {
        state.companyPayouts.status = STATE_SUCCEEDED
        state.companyPayouts.payouts = action.payload.companyPayouts
        state.companyPayouts.totalCount = action.payload.totalCount
        state.companyPayouts.pageNumber = action.payload.pageNumber
        state.companyPayouts.pageSize = action.payload.pageSize
      })
      .addCase(listCompanyPayoutsAsync.rejected, (state, action) => {
        state.companyPayouts.status = STATE_FAILED
        console.error(action.error)
        state.companyPayouts.error = {
          message: action.error
        }
      })
      .addCase(listProjectsPayoutsAsync.pending, (state) => {
        state.projectsPayouts.status = STATE_LOADING
      })
      .addCase(listProjectsPayoutsAsync.fulfilled, (state, action) => {
        state.projectsPayouts.status = STATE_SUCCEEDED
        state.projectsPayouts.payouts = action.payload.payouts
        state.projectsPayouts.totalCount = action.payload.totalCount
        state.projectsPayouts.pageNumber = action.payload.pageNumber
        state.projectsPayouts.pageSize = action.payload.pageSize
      })
      .addCase(listProjectsPayoutsAsync.rejected, (state, action) => {
        state.projectsPayouts.status = STATE_FAILED
        console.error(action.error)
        state.projectsPayouts.error = {
          message: action.error
        }
      })
  }
})

export default projectPayoutSlice.reducer
export const {
  showPayoutAddModal,
  showEditModal,
  closeModal,
  addPaidFromOrderToPayout,
  addPaidFromJobtoPayout,
  addPayout,
  updatePayout,
  updateSumTotal,
  addCompanyPayout,
  updateCompanyPayout,
  deleteCompanyPayoutSlice,
  addProjectsPayout,
  updateProjectsPayout,
  deleteProjectsPayoutSlice
} = projectPayoutSlice.actions
