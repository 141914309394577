import { axiosClient } from './base'

const LIST_OFFERS = '/api/offer'
const GET_OFFER = (id) => `/api/offer/${id}`
const OFFER_ITEMS = (id) => `/api/offer/${id}/item`
const OFFER_ITEM = (id) => `/api/offer-item/${id}`
const OFFER_SUPPLIERS = (id) => `/api/offer/${id}/supplier`
// const OFFER_SUPPLIER_PRICE = (id) => `/api/offer-price/${id}`
const OFFER_SUPPLIER_PRICE = (id) => `/api/offer-supplier/${id}`

export const list = ({ pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_OFFERS, {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const addNewOffer = () => axiosClient.post(LIST_OFFERS)

export const getOffer = (offerId) => axiosClient.get(GET_OFFER(offerId))

export const addOfferItem = (offerId, { itemId, quantity }) =>
  axiosClient.post(OFFER_ITEMS(offerId), {
    itemId,
    quantity
  })

export const getOfferItems = (offerId, { pageNumber, pageSize, q }) =>
  axiosClient.get(OFFER_ITEMS(offerId), {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const editOfferItem = (itemId, { quantity }) =>
  axiosClient.put(OFFER_ITEM(itemId), { quantity })

export const deleteOfferItem = (itemId) =>
  axiosClient.delete(OFFER_ITEM(itemId))

export const getOfferSuppliers = (offerId) =>
  axiosClient.get(OFFER_SUPPLIERS(offerId))

export const addOfferSupplier = (offerId, { supplierId }) =>
  axiosClient.post(OFFER_SUPPLIERS(offerId), { supplierId })

export const updateOfferSupplierPrice = (offerSupplierId, { offerSupplierItems }) =>
  axiosClient.put(OFFER_SUPPLIER_PRICE(offerSupplierId), { offerSupplierItems })

export const listOfferItemsByLink = (link) =>
  axiosClient.get(`/api/offer-link/${link}`)
