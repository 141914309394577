import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listSuppliers } from '../../api/supplier'
import { listSupplierPayouts } from '../../api/payout'

const initialState = {
  suppliers: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 12,
  q: '',
  status: STATE_IDLE,
  error: null,
  initialSuppliersSearchState: {
    fetched: false,
    suppliers: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: ''
  },
  supplierPayouts: {
    status: STATE_IDLE,
    error: null,
    payouts: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    sumOrderTotal: 0,
    sumTotal: 0,
    q: ''
  }
}

export const listSuppliersAsync = createAsyncThunk(
  'supplier/list',
  async ({ pageSize, pageNumber, q } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 12
    const listResult = await listSuppliers({ pageSize, pageNumber, q })
    return listResult.data
  }
)
export const listSupplierPayoutsAsync = createAsyncThunk(
  'supplierPayouts/list',
  async ({ supplierId, pageSize, pageNumber, projectId }) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 20
    const listResult = await listSupplierPayouts(supplierId, {
      pageSize,
      pageNumber,
      projectId
    })
    console.log(listResult.data)
    return listResult.data
  }
)
export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    addPayoutToSupplier: (state, action) => {
      state.supplierPayouts.payouts.unshift(action.payload)
    },
    updateSupplierPayout: (state, action) => {
      state.supplierPayouts.payouts = state.supplierPayouts.payouts.map(
        (payout) => {
          if (payout.id === action.payload.id) {
            return {
              ...payout,
              amount: action.payload.amount,
              payoutDate: action.payload.payoutDate
            }
          } else {
            return payout
          }
        }
      )
    },
    deletePayout: (state, action) => {
      state.supplierPayouts.payouts = state.supplierPayouts.payouts.filter(
        (payout) => payout.id !== action.payload.id
      )
    },
    updateTotalPaidAfterAddPayout: (state, action) => {
      state.supplierPayouts.sumTotal += +action.payload
    },
    updateTotalPaidAfterEdit: (state, action) => {
      state.supplierPayouts.sumTotal +=
        +action.payload.new - +action.payload.old
    },
    updateTotalPaidAfterDeletePayout: (state, action) => {
      state.supplierPayouts.sumTotal -= +action.payload
    },
    deleteSupplier: (state, action) => {
      state.suppliers = state.suppliers.filter(
        (supplier) => supplier.id !== action.payload
      )
    },
    useInitialSuppliersSearchState: (state) => {
      state.suppliers = state.initialSuppliersSearchState.suppliers
      state.totalCount = state.initialSuppliersSearchState.totalCount
      state.pageNumber = state.initialSuppliersSearchState.pageNumber
      state.pageSize = state.initialSuppliersSearchState.pageSize
      state.q = state.initialSuppliersSearchState.q
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSuppliersAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listSuppliersAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.suppliers = action.payload.suppliers
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
        if (
          !state.initialSuppliersSearchState.fetched ||
          (state.pageNumber !== state.initialSuppliersSearchState.pageNumber &&
            !state.q)
        ) {
          state.initialSuppliersSearchState = {
            fetched: true,
            suppliers: action.payload.suppliers,
            totalCount: action.payload.totalCount,
            pageNumber: action.payload.pageNumber,
            pageSize: action.payload.pageSize,
            q: action.payload.q
          }
        }
      })
      .addCase(listSuppliersAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        state.error = {
          message: action.error
        }
      })
      .addCase(listSupplierPayoutsAsync.pending, (state) => {
        state.supplierPayouts.status = STATE_LOADING
      })
      .addCase(listSupplierPayoutsAsync.fulfilled, (state, action) => {
        state.supplierPayouts.status = STATE_SUCCEEDED
        state.supplierPayouts.payouts = action.payload.payouts
        state.supplierPayouts.totalCount = action.payload.totalCount
        state.supplierPayouts.pageNumber = action.payload.pageNumber
        state.supplierPayouts.pageSize = action.payload.pageSize
        state.supplierPayouts.sumOrderTotal = action.payload.sumOrderTotal
        state.supplierPayouts.sumTotal = action.payload.sumTotal
        state.supplierPayouts.q = action.payload.q
      })
      .addCase(listSupplierPayoutsAsync.rejected, (state, action) => {
        state.supplierPayouts.status = STATE_FAILED
        console.error(action.error)
        state.supplierPayouts.error = {
          message: action.error
        }
      })
  }
})

export default supplierSlice.reducer
export const {
  deleteSupplier,
  useInitialSuppliersSearchState,
  addPayoutToSupplier,
  updateTotalPaidAfterAddPayout,
  updateTotalPaidAfterEdit,
  updateSupplierPayout,
  updateTotalPaidAfterDeletePayout,
  deletePayout
} = supplierSlice.actions
