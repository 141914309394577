import { axiosClient } from './base'

const LIST_CLIENTS = '/api/client'
const CREATE_NEW_CLIENT = '/api/client'
const EDIT_CLIENT = (id) => `/api/client/${id}`
const DELETE_CLIENT = (id) => `/api/client/${id}`
const CLIENT_PROJECTS = (id) => `/api/project?clientId=${id}`

export const list = ({ pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_CLIENTS, {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const addNewClient = ({ name, phoneNumber, address, phoneNumber2 }) =>
  axiosClient.post(CREATE_NEW_CLIENT, {
    name,
    phoneNumber,
    phoneNumber2,
    address
  })

export const editClient = (clientId, clientDetails) =>
  axiosClient.put(EDIT_CLIENT(clientId), clientDetails)

export const deleteClient = (clientId) =>
  axiosClient.delete(DELETE_CLIENT(clientId))

export const getClient = (clientId) => axiosClient.get(EDIT_CLIENT(clientId))

export const listClientProjects = (clientId) => axiosClient.get(CLIENT_PROJECTS(clientId))
