import { axiosClient } from './base'

const ADD_NEW_ORDER = (id) => `/api/project/${id}/order`
const EDIT_ORDER = (id, orderId) => `/api/project/${id}/order/${orderId}`
const DELETE_ORDER = (id, orderId) => `/api/project/${id}/order/${orderId}`
const LIST_PROJECT_ORDERS = (id) => `/api/project/${id}/order`

const GET_ORDER_ITEMS = (id, orderID) => `/api/project/${id}/order/${orderID}/item`
const ORDER_ITEM = (id, orderID, itemID) =>
  `/api/project/${id}/order/${orderID}/item/${itemID}`
const UPLOAD_ORDER_IMAGE = (projectId, orderId) => `/project/${projectId}/order/${orderId}/upload`
const GET_ORDER = (id, orderID) =>
  `/api/project/${id}/order/${orderID}`

export const addNewOrder = (
  projectId,
  data
) =>
  axiosClient.post(ADD_NEW_ORDER(projectId), data)

export const uploadImage = (projectId, orderID, file) =>
  axiosClient.post(UPLOAD_ORDER_IMAGE(projectId, orderID), file)

export const editOrder = (
  projectId,
  orderId,
  data
) =>
  axiosClient.put(EDIT_ORDER(projectId, orderId), data)
export const deleteOrder = (projectId, orderId) => axiosClient.delete(DELETE_ORDER(projectId, orderId))

export const listProjectOrders = (
  projectId,
  { pageNumber, pageSize, q, projectServiceID, all }
) =>
  axiosClient.get(LIST_PROJECT_ORDERS(projectId), {
    params: {
      pageNumber,
      pageSize,
      q,
      serviceId: projectServiceID,
      all
    }
  })

export const getOrder = (id, orderID) => axiosClient.get(GET_ORDER(id, orderID))

export const getOrderItems = (
  projectId,
  orderID,
  { pageNumber, pageSize, q, all }
) =>
  axiosClient.get(GET_ORDER_ITEMS(projectId, orderID), {
    params: {
      pageNumber,
      pageSize,
      q,
      all
    }
  })

export const addItemToOrder = (
  projectId,
  orderID,
  { itemId, quantity, price, total }
) =>
  axiosClient.post(GET_ORDER_ITEMS(projectId, orderID), {
    itemId,
    quantity,
    price,
    total
  })

export const editOrderItem = (
  projectId,
  orderID,
  itemID,
  { total, price, quantity }
) =>
  axiosClient.put(ORDER_ITEM(projectId, orderID, itemID), {
    total,
    price,
    quantity
  })

export const deleteOrderItem = (projectId, orderID, itemID) =>
  axiosClient.delete(ORDER_ITEM(projectId, orderID, itemID))
