import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_FAILED,
  STATE_IDLE,
  STATE_LOADING,
  STATE_SUCCEEDED
} from '../../constants'
import { list } from '../../api/installment'

export const listInstallmentsAsync = createAsyncThunk(
  'installment/list',
  async ({ pageSize, pageNumber, unitId }) => {
    const listResult = await list({ pageSize, pageNumber, unitId })

    return listResult.data
  }
)
const installmentSlice = createSlice({
  name: 'installment',
  initialState: {
    installments: [],
    unitTotal: 0,
    paid: 0,
    due: 0,
    totalCount: 0,
    pageNumber: 1,
    pageSize: 10,
    status: STATE_IDLE,
    error: ''
  },
  reducers: {
    addInstallment(state, action) {
      state.installments.push(action.payload)
    },
    editInstallment(state, action) {
      state.installments = state.installments.map((installment) => {
        if (installment.id === action.payload.id) {
          return action.payload
        } else {
          return installment
        }
      })
    },
    removeInstallment(state, action) {
      state.installments = state.installments.filter(
        (installment) => installment.id !== action.payload
      )
    }
  },
  extraReducers(builder) {
    builder.addCase(listInstallmentsAsync.pending, (state) => {
      state.status = STATE_LOADING
    })
    builder.addCase(listInstallmentsAsync.fulfilled, (state, action) => {
      state.status = STATE_SUCCEEDED
      state.installments = action.payload.installments
      state.totalCount = action.payload.totalCount
      state.pageNumber = action.payload.pageNumber
      state.pageSize = action.payload.pageSize
    })
    builder.addCase(listInstallmentsAsync.rejected, (state, action) => {
      state.status = STATE_FAILED
      console.error(action.error)
      state.error = {
        message: action.error
      }
    })
  }
})

export default installmentSlice.reducer
export const { addInstallment, editInstallment, removeInstallment } =
  installmentSlice.actions
