import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { list, listClientProjects, getClient } from '../../api/client'

const initialState = {
  clients: [],
  client: {
    client: {},
    status: STATE_IDLE,
    error: null
  },
  totalCount: 0,
  pageNumber: 1,
  pageSize: 12,
  q: '',
  status: STATE_IDLE,
  error: null,
  clientProjects: {
    status: STATE_IDLE,
    projects: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: ''
  },
  initialClientsSearchState: {
    fetched: false,
    clients: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: ''
  }
}

export const listClientsAsync = createAsyncThunk(
  'client/list',
  async ({ pageSize, pageNumber, q } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 12

    const listResult = await list({ pageSize, pageNumber, q })
    return listResult.data
  }
)

export const listClientProjectsAsync = createAsyncThunk(
  'clientProject/list',
  async (clientId, { pageSize, pageNumber } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 6
    const listResult = await listClientProjects(clientId, {
      pageSize,
      pageNumber
    })
    return listResult.data
  }
)

export const getClientAsync = createAsyncThunk(
  'client/get-one',
  async (clientId) => {
    const client = await getClient(clientId)
    return client.data
  }
)

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    deleteClient: (state, action) => {
      state.clients = state.clients.filter(
        (client) => client.id !== action.payload
      )
    },
    useInitialClientsSearchState: (state) => {
      state.clients = state.initialClientsSearchState.clients
      state.totalCount = state.initialClientsSearchState.totalCount
      state.pageNumber = state.initialClientsSearchState.pageNumber
      state.pageSize = state.initialClientsSearchState.pageSize
      state.q = state.initialClientsSearchState.q
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listClientsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listClientsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.clients = action.payload.clients
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
        if (
          !state.initialClientsSearchState.fetched ||
          (state.pageNumber !== state.initialClientsSearchState.pageNumber &&
            !state.q)
        ) {
          state.initialClientsSearchState = {
            fetched: true,
            clients: action.payload.clients,
            totalCount: action.payload.totalCount,
            pageNumber: action.payload.pageNumber,
            pageSize: action.payload.pageSize,
            q: action.payload.q
          }
        }
      })
      .addCase(listClientsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        let message
        state.error = {
          message
        }
      })
      .addCase(listClientProjectsAsync.pending, (state) => {
        state.clientProjects.status = STATE_LOADING
      })
      .addCase(listClientProjectsAsync.fulfilled, (state, action) => {
        state.clientProjects.status = STATE_SUCCEEDED
        state.clientProjects.projects = action.payload.projects
        state.clientProjects.pageNumber = action.payload.pageNumber
        state.clientProjects.pageSize = action.payload.pageSize
        state.clientProjects.totalCount = action.payload.totalCount
      })
      .addCase(listClientProjectsAsync.rejected, (state) => {
        state.clientProjects.status = STATE_FAILED
      })
      .addCase(getClientAsync.pending, (state) => {
        state.client.status = STATE_LOADING
      })
      .addCase(getClientAsync.fulfilled, (state, action) => {
        state.client.status = STATE_SUCCEEDED
        state.client.client = action.payload.client
      })
      .addCase(getClientAsync.rejected, (state) => {
        state.client.status = STATE_FAILED
      })
  }
})

export default clientSlice.reducer
export const { deleteClient, useInitialClientsSearchState } =
  clientSlice.actions
