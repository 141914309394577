import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listContractors } from '../../api/contractor'
import { listContractorPayouts } from './../../api/payout'

const initialState = {
  contractors: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 12,
  q: '',
  status: STATE_IDLE,
  error: null,
  initialContractorsSearchState: {
    fetched: false,
    contractors: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 12,
    q: ''
  },
  contractorPayouts: {
    status: STATE_IDLE,
    error: null,
    payouts: [],
    pageNumber: 1,
    pageSize: 20,
    totalCount: 0,
    sumJobtotal: 0,
    sumTotal: 0,
    q: ''
  }
}

export const listContractorsAsync = createAsyncThunk(
  'contractor/list',
  async ({ pageSize, pageNumber, q } = {}) => {
    pageNumber = pageNumber || 1
    pageSize = pageSize || 12
    const listResult = await listContractors({ pageSize, pageNumber, q })
    return listResult.data
  }
)

export const listContractorPayoutsAsync = createAsyncThunk(
  'contractorPayouts/list',
  async ({
    contractorId,
    pageSize,
    pageNumber,
    jobId,
    projectId,
    fromDate,
    toDate
  }) => {
    console.log(pageNumber)
    pageNumber = pageNumber || 1
    pageSize = pageSize || 20
    const listResult = await listContractorPayouts(contractorId, {
      pageSize,
      pageNumber,
      jobId,
      projectId,
      fromDate,
      toDate
    })
    return listResult.data
  }
)
export const contractorSlice = createSlice({
  name: 'contractor',
  initialState,
  reducers: {
    deleteContractor: (state, action) => {
      state.contractors = state.contractors.filter(
        (contractor) => contractor.id !== action.payload
      )
    },
    useInitialContractorsSearchState: (state) => {
      state.contractors = state.initialContractorsSearchState.contractors
      state.totalCount = state.initialContractorsSearchState.totalCount
      state.pageNumber = state.initialContractorsSearchState.pageNumber
      state.pageSize = state.initialContractorsSearchState.pageSize
      state.q = state.initialContractorsSearchState.q
    },
    addPayoutToContractor: (state, action) => {
      const newPayout = {
        ...action.payload.newPayout,
        job: { name: action.payload.job }
      }
      state.contractorPayouts.payouts.unshift(newPayout)
    },
    updateContractorPayout: (state, action) => {
      state.contractorPayouts.payouts = state.contractorPayouts.payouts.map(payout => {
        if (payout.id === action.payload.id) {
          return {
            ...payout,
            amount: action.payload.amount,
            payoutDate: action.payload.payoutDate
          }
        } else {
          return payout
        }
      })
    },
    deletePayout: (state, action) => {
      state.contractorPayouts.payouts = state.contractorPayouts.payouts.filter(payout => payout.id !== action.payload.id)
    },
    updateTotalPaidAfterEdit: (state, action) => {
      state.contractorPayouts.sumTotal += +action.payload.new - +action.payload.old
    },
    updateTotalPaidAfterAddPayout: (state, action) => {
      state.contractorPayouts.sumTotal += +action.payload
    },
    updateTotalPaidAfterDeletePayout: (state, action) => {
      state.contractorPayouts.sumTotal -= +action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listContractorsAsync.pending, (state) => {
        state.status = STATE_LOADING
      })
      .addCase(listContractorsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.contractors = action.payload.contractors
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
        if (
          !state.initialContractorsSearchState.fetched ||
          (state.pageNumber !==
            state.initialContractorsSearchState.pageNumber &&
            !state.q)
        ) {
          state.initialContractorsSearchState = {
            fetched: true,
            contractors: action.payload.contractors,
            totalCount: action.payload.totalCount,
            pageNumber: action.payload.pageNumber,
            pageSize: action.payload.pageSize,
            q: action.payload.q
          }
        }
      })
      .addCase(listContractorsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
      .addCase(listContractorPayoutsAsync.pending, (state) => {
        state.contractorPayouts.status = STATE_LOADING
      })
      .addCase(listContractorPayoutsAsync.fulfilled, (state, action) => {
        state.contractorPayouts.status = STATE_SUCCEEDED
        state.contractorPayouts.payouts = action.payload.payouts
        state.contractorPayouts.totalCount = action.payload.totalCount
        state.contractorPayouts.pageNumber = action.payload.pageNumber
        state.contractorPayouts.pageSize = action.payload.pageSize
        state.contractorPayouts.sumJobtotal = action.payload.sumJobtotal
        state.contractorPayouts.sumTotal = action.payload.sumTotal
        state.contractorPayouts.q = action.payload.q
      })
      .addCase(listContractorPayoutsAsync.rejected, (state, action) => {
        state.contractorPayouts.status = STATE_FAILED
        console.error(action.error)
        state.contractorPayouts.error = {
          message: action.error
        }
      })
  }
})

export default contractorSlice.reducer
export const {
  deleteContractor,
  useInitialContractorsSearchState,
  addPayoutToContractor,
  updateContractorPayout,
  deletePayout,
  updateTotalPaidAfterEdit,
  updateTotalPaidAfterAddPayout,
  updateTotalPaidAfterDeletePayout
} = contractorSlice.actions
